import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import apiurl from "../apiUrl";

function shorText(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    } else {
        return text.slice(0, maxLength - 3) + '...';
    }
}

function FormListItems({ form }) {
    const [isformDeleted, setFormisDeleted] = React.useState(false);

    const deleteItem = async (formid) => {
        const confirmDelete = window.confirm("Möchten Sie das Formular wirklich löschen?");

        if (confirmDelete) {
            try {
                const response = await axios.post(apiurl + 'deleteform.php', { formid }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    console.log('Daten erfolgreich gesendet.' + response.data);
                    setFormisDeleted(true);
                } else {
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            } catch (error) {
                console.error('Fehler beim Senden der Daten:', error);
            }
        }
    };

    const resetForm = () => {
        setFormisDeleted(false);
        window.location.href = '/forms';
    };

    return (
        <>
            <tr>
                <td>{form.frm_name}</td>
                <td><Link to={`/backend/forms/edit/${form.id}`}>Bearbeiten</Link></td>
                <td>
                    <span className="deletebutton" onClick={() => deleteItem(form.id)}>
                        Löschen
                    </span>
                </td>
            </tr>
        </>
    );
}

export default FormListItems;
