


import React from 'react';
import HomeBackend from './pages/HomeBackend';
import Customers from "./customers/Customers";
import Jobs from './jobs/Jobs';
import JobsAdd from './jobs/JobsAdd';
import JobsEdit from './jobs/JobsEdit';
import Forms from './forms/Forms';
import FormAdd from './forms/FormAdd';
import FormEdit from './forms/FormEdit';
import Tracking from './tracking/Tracking';
import Pages from './pages/Pages';
import Uns from './pages/Uns';
import Imprint from './pages/Imprint';
import BackendDSE from './pages/BackendDSE';
import Home from './pages/Home';
import Login from './Login/Login';
import HeaderBackend from './layout/HeaderBackend';
import Settings from './settings/Settings';
import { Routes, Route } from 'react-router-dom';
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function Backend() {
    const [navIsCollapsed, setNaviIsCollapsed] = React.useState(false);
    const [isLogged, setIsLogged] = React.useState(false);
    let cookie = getCookie("loggedin");


    return (
        <section className="mio-office-master-section">
            {
                isLogged || cookie === "yes" ? (
                    <>
                        <HeaderBackend setIsLogged={setIsLogged} navIsCollapsed={navIsCollapsed} setNaviIsCollapsed={setNaviIsCollapsed} />
                        <Routes>
                            <Route path="/backend/" element={<HomeBackend isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/customers" element={<Customers isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/jobs" element={<Jobs isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/jobs/add" element={<JobsAdd isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/forms" element={<Forms isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/forms/add" element={<FormAdd isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/forms/edit/:formid" element={<FormEdit isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/tracking" element={<Tracking isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/jobs/edit/:jobid" element={<JobsEdit isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/pages" element={<Pages isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/pages/uns" element={<Uns navisCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/pages/impressum" element={<Imprint isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/pages/datenschutz" element={<BackendDSE isCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/pages/startseite" element={<Home navisCollapsed={navIsCollapsed} />} />
                            <Route path="/backend/settings" element={<Settings isCollapsed={navIsCollapsed} />} />
                        </Routes>
                    </>
                ) : (
                    <Login setIsLoggedIn={setIsLogged} />
                )
            }
        </section>
    );
}

export default Backend;
