import React from "react";
import axios from "axios";
import apiurl from "../apiUrl";

function FormQuestionItems({ formid, setShowForm, setQuestions }) {
    const [sendQuestData, setSendQuestData] = React.useState({ frm_id: formid, fq_question: "", fq_isfield:'',fq_isrequired:'' });
    const fetchurl = apiurl + "getfrmquestion.php";

    const saveData = async () => {
        try {
            const response = await axios.post(apiurl + 'savefrmquestion.php', sendQuestData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log('Daten erfolgreich gesendet.' + response.data);
                setSendQuestData({ frm_id: 0, fq_question: "" });
                setShowForm(false);
                loadData();
            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    }

        async function loadData() {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, { formId: formid }); // Benutze "formId" hier
                const result = response.data;
                setQuestions(result);
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }

    return (
        <section className="questionform">
            <div className="mio-form-field tab-flex-4 flex-6">
                <label>Frage</label>
                <input
                    type="text"
                    value={sendQuestData.fq_question}

                    onChange={(e) => setSendQuestData({ ...sendQuestData, frm_id: formid, fq_question: e.target.value })}
                />
                              <input
                    type="hidden"
                    value={formid}
                    onChange={(e) => setSendQuestData({ ...sendQuestData, frm_id: formid })} // Du kannst die versteckte Eingabe entfernen, da frm_id bereits in sendQuestData ist
                />
            </div>
            <div className="mio-form-field tab-flex-4 flex-6">
                <button
                    className="cta-button"
                    onClick={saveData}>
                    Frage speichern
                </button>
            </div>
        </section>
    )
}

export default FormQuestionItems;
