import React from "react";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";
import apiurl from "../apiUrl";
import JobsListItems from "./JobsListItems";
import { Link } from "react-router-dom";
function Jobs({ isCollapsed }) {
    const fetchUrl = apiurl + "getjobdata.php";
    const [jobs, setJobs] = React.useState([]);
   React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(fetchUrl);
                if (response.ok) {
                    const data = await response.json();
                    setJobs(data);
                } else {
                    throw new Error("Failed to fetch job data");
                }
            } catch (error) {
                console.error("Error fetching job data:", error);
            }
        };

        fetchData();
    }, [fetchUrl]);

    return (
        <BackendMainContainer navIsCollapsed={isCollapsed}>
            <div className={"mioui-section-list"}>
                <h1>Stellenanzeigen</h1>
                {jobs.length === 0 ? (
                    <>
                        <p>Noch keine Stellenanzeigen geschaltet.</p>
                        <Link to="/backend/jobs/add">Neue Anzeige verfassen</Link>
                    </>
                ) : (
                    <table>
                        <thead>
                        <tr>
                            <th>Anzeigentitel:</th>
                            <th>Bearbeiten</th>
                            <th>Löschen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {jobs.map((job) => (
                            <JobsListItems key={job.anz_id} job={job} />
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
        </BackendMainContainer>
    );
}

export default Jobs;
