import React from "react";
import videourl from "../videourl";
import playercss from "../styles/css/contentparts/videoplayer.module.css";
import buttonsvg from "../styles/img/play-button.svg";
function VideoPlayer({videoHeadline,showvideascontent, videoText,videocontent}){
    const [isModal, setIsModal] = React.useState(false);

    function handleModal(){
        setIsModal(false);
    }
    const refVideo = React.useRef();

    function playVideo(){

        setIsModal(true);

    }



    const videsource = videourl +'/'+videocontent.pvi_path;
    const videoposter = videourl + '/video-thumbnail.webp';
    return(

        <section className={playercss.videoframe}>
            {
                showvideascontent ?
                    (
                        <section className={playercss.videocontentframe}>

                            <section className={playercss.videcontent}>

                                <h2>{videoHeadline}</h2>
                                <div className={playercss.content}   dangerouslySetInnerHTML={{ __html: videoText }} />

                            </section>
                            <section className={playercss.buttonframe}>

                                <button className={playercss.videobutton} onClick={playVideo}>
                            <span>
                                <img src={buttonsvg} alt="Video"/>
                            </span>





                                </button>


                            </section>

                        </section>







                    )

                    :(
                        <>
                            <video className={playercss.videoelement}
                                   ref={refVideo}
                                   src={videsource}
                                   poster={videoposter}
                                   width="100%"
                                   height="100%"

                                   controls

                            />

                            <section className={playercss.videocontent}>
                            </section>
                        </>
                    )}

            {
                isModal

                    ? (

                        <section className={playercss.modawlrapper}>
                            <section className={playercss.videplayercontainer}>

                                <span className={playercss.closebutton} onClick={ handleModal }>X</span>


                                <video className={playercss.videoelement}
                                       ref={refVideo}
                                       src={videsource}
                                       poster={videoposter}
                                       width="100%"
                                       height="100%"
                                       autoPlay={true}
                                       controls

                                />




                            </section>

                        </section>

                    ):("")

            }








        </section>

    )

}
export default VideoPlayer