import React, { useState } from "react";
import axios from "axios";
import fqstyles from "./formqalist.module.css";
import FormAnswerEditItems from "./FormAnswerEditItems";
import FormAnswerItems from "./FormAnwerItems";
import apiurl from "../apiUrl";



function FormQAListEdit({ dataQuestion, setQuestions, formID }) {

    const [showInput, setShowInput] = React.useState(dataQuestion.map(() => false));
    const [showAnswers, setShowAnswers] = React.useState(dataQuestion.map(() => false));
    const [questionContent, setQuestionContent] = React.useState("");
    const [hasTexField, setHastextField] = React.useState('');
    const [TexFieldRequired, setTexFieldRequired] = React.useState('');


    const [showNewAnswerForm, setNewAnswerform] = React.useState(dataQuestion.map(() => false));
    const [elementPosition, setElementPosition] = React.useState({id:0, direction:"up"})
    const fetchurl = apiurl + "getfrmquestion.php";

    async function loadData() {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, { formId: formID }); // Benutze "formId" hier
                const result = response.data;
                setQuestions(result);
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }

    const toggleShowAnswers = (index,fnq_id) => {
        const updatedShowAnswers = [...showAnswers];
        updatedShowAnswers[index] = !updatedShowAnswers[index];
        setShowAnswers(updatedShowAnswers);
    };
    const toggleShooqAddform = (index,fnq_id) => {
        const updatedShowAddform = [...showNewAnswerForm];
        updatedShowAddform[index] = !updatedShowAddform[index];
        setNewAnswerform(updatedShowAddform);
    };

    const editItem = (index,id) =>{

        const updatedShowAnswers = [...showInput];
        updatedShowAnswers[index] = !updatedShowAnswers[index];
        setShowInput(updatedShowAnswers);
        setQuestionContent("");

    }

    const upPosition = (index, ansid) => {
        const updatedElementPosition = { id: ansid, direction: "up" };
        setElementPosition(updatedElementPosition);


        axios.post(apiurl + 'updatefrmquestions.php', updatedElementPosition, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.status === 200) {
                console.log('Daten erfolgreich gesendet.' + response.data);
                loadData();

            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        }).catch(error => {
            console.error('Fehler beim Senden der Daten:', error);
        });
    }



    const deleteItem = async (index,itemid) =>{

        const confirm = window.confirm("Möchten Sie die Frage wirklich löschen?");
        if(confirm) {
            try {

                const response = await axios.post(apiurl + 'deletefrmquestion.php', {itemid}, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    console.log('Daten erfolgreich gesendet.' + response.data);
                    loadData();
                } else {
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            } catch (error) {
                console.error('Fehler beim Senden der Daten:', error);
            }
        }
    }
    const updateContent = async (index, id) => {
        if (questionContent !== "") {
            const updatedData = { content: questionContent, questionid: id};

            try {
                const response = await axios.post(apiurl + 'updatefrmquestion.php', updatedData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    console.log('Daten erfolgreich gesendet.' + response.data);
                    const updatedShowAnswers = [...showInput];
                    updatedShowAnswers[index] = !updatedShowAnswers[index];
                    setShowInput(updatedShowAnswers);
                    loadData();

                } else {
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            } catch (error) {
                console.error('Fehler beim Senden der Daten:', error);
            }
        } else {
            const updatedShowAnswers = [...showInput];
            updatedShowAnswers[index] = !updatedShowAnswers[index];
            setShowInput(updatedShowAnswers);
        }
    };
    return (
        <section className={fqstyles.frmdatalist}>
            {dataQuestion.map((dq, index) => (
                <section
                    className="questlistitem"
                    key={dq.fmq_id}

                >
                    <span className={
                        !showInput[index]
                            ? fqstyles.answeritems + " " + fqstyles.aivisible
                            : fqstyles.answeritems
                    }
                    >
{
    <h5 title="Bite klicken um die Antworten darzustellen." onClick={() => toggleShowAnswers(index, dq.fmq_id)}
        className={fqstyles.questionheadline}>{dq.fmq_question}


    </h5>

}

    </span>
                <section className={fqstyles.questioneditform}>
                    <section className={
                        showInput[index]
                            ? fqstyles.answerinput + " " + fqstyles.aivisible
                            : fqstyles.answerinput
                    }
                    >

                        <input
                            type="text"
                            className={fqstyles.inquestion}
                            value={questionContent || dq.fmq_question}
                            onChange={(e)=> setQuestionContent(e.target.value)}

                        />
                         <span onClick={()=>updateContent(index,dq.fmq_id)} className={fqstyles.listupdatebutton}>OK</span>
                    </section>

                </section>

                    <div className={fqstyles.buttonlist}>
                        <span onClick={()=>deleteItem(index,dq.fmq_id)} title={dq.fmq_question + " löschen"} className={fqstyles.deletebutton}>Löschen</span>
                        <span onClick={()=>editItem(index,dq.fmq_id)}title={dq.fmq_question + " bearbeiten"} className={fqstyles.editbutton}>Bearbeiten</span>
                        <span onClick={()=>upPosition(index,dq.fmq_id)}title={dq.fmq_question + " eine Postion nach oben"} className={


                                dq.fmq_order > 1

                                    ? (fqstyles.posup)
                                    :  (fqstyles.noposup)



                            }>Nach Oben</span>




                    </div>
            <section

                className={
                showNewAnswerForm[index]
                     ? fqstyles.answeraddform + " " + fqstyles.afvisible
                     :   fqstyles.answeraddform

                }
                >
                <FormAnswerItems questionId={dq.fmq_id} setShowAnswer={true}/>


            </section>




                    <section
                        className={
                            showAnswers[index]
                                ? fqstyles.answeritems + " " + fqstyles.aivisible
                                : fqstyles.answeritems
                        }
                    >
                        <p>Die Antworten</p>
                        <button
                            className={fqstyles.addanswerbutton}
                            onClick={()=>toggleShooqAddform(index)}>
                        Neue Antwort hinzufügen
                        </button>
                        <FormAnswerEditItems questionid={dq.fmq_id} > </FormAnswerEditItems>
                    </section>
                </section>
            ))}
        </section>
    );
}

export default FormQAListEdit;
