import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import apiurl from "../apiUrl";
import FileUploader from "../filemanagement/FileUploader";

function JobAddForm() {
    const [formAddsend, setFormAddSend] = useState(false);
    const [formFields, setFormFields] = useState({
        anz_vorteile_title: '',
        anz_aufgaben_title: '',
        anz_title: '',
        anz_start: '',
        anz_routing: '',
        anz_slogan: '',
        anz_desc: '',
        anz_place: '',
        anz_name_asp: '',
        anz_mail_asp: '',
        anz_job_type: '',
        anz_vorteile: '',
        anz_aufgaben: '',
        anz_slogan_title: ''
    });
    const [showSecondForm, setShowSecondForm] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [files, setFiles] = useState({ area: 'jobs', sendfiles: [], id: 99 });
    const [jobid, setJobid] = useState(files.id);
    const fetchurl = apiurl + "getjoblastid.php";
    const refImageUploader = useRef();

    const handleUploader = () => {
        refImageUploader.current.click();
    }

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await fetch(fetchurl);
                const result = await response.json();
                setFiles((prevFiles) => ({ ...prevFiles, id: result }));
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        if (formAddsend && !showSuccess) {
            getData();
        }
    }, [formAddsend, showSuccess, fetchurl]);

    const handleForm = async (e) => {
        e.preventDefault();

        try {
            if (!formAddsend) {
                const response = await axios.post(apiurl + 'savejobdata.php', formFields, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status === 200) {
                    setFormAddSend(true);
                    setShowSecondForm(true);
                } else {
                    console.error('Fehler bei der Anfrage:', response.status);
                }
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        const fileList = files.sendfiles;
        formData.append('area', files.area);
        formData.append('jobid', jobid);

        try {
            for (let i = 0; i < fileList.length; i++) {
                formData.append('sendfiles[]', fileList[i]);
            }

            const response = await axios.post(apiurl + 'fileuploader.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                setJobid(response.data.jobid);
                setShowSuccess(true);
            } else {
                console.error('Fehler bei der Anfrage:', response.status);
            }
        } catch (error) {
            console.error('Fehler beim Senden der Daten:', error);
        }
    };

    return (
        <section className="mio-form-fields">
            <div className="inlay">
                <div className="flexrow">
                    <div className="mio-form-field tab-flex-8 flex-12">
                        <label htmlFor="slogan">Job Slogan</label>
                        <input
                            id="slogan"
                            type="text"
                            value={formFields.anz_slogan}
                            onChange={(e) => setFormFields({ ...formFields, anz_slogan: e.target.value })}
                        />
                    </div>
                    <div className="mio-form-field tab-flex-8 flex-12">
                        <label htmlFor="slogantitle">Text veränderbarer Jobtitel</label>
                        <input
                            id="slogantitle"
                            type="text"
                            value={formFields.anz_slogan_title}
                            onChange={(e) => setFormFields({ ...formFields, anz_slogan_title: e.target.value })}
                        />
                    </div>
                    <div className="mio-form-field tab-flex-8 flex-12">
                        <label htmlFor="jobtitel">Jobtitel</label>
                        <input
                            id="jobtitel"
                            type="text"
                            value={formFields.anz_title}
                            onChange={(e) => setFormFields({ ...formFields, anz_title: e.target.value })}
                        />
                    </div>
                    {/* Weitere Formularfelder hier hinzufügen */}

                    <div className="mio-form-field tab-flex-8 flex-6">
                        <button className="cta-button" onClick={handleForm}>
                            Weiter zum Bildupload
                        </button>
                    </div>
                </div>
            </div>

            {showSecondForm && (
                <section className="secondform">
                    <h2>Bilder für das Jobangebot {formFields.anz_title} hochladen</h2>
                    <span title="Bilder hinzufügen" className="addimage" onClick={handleUploader}></span>
                    <FileUploader files={files} setFiles={setFiles} refuploadLogo={refImageUploader} />
                    <button className="cta-button" onClick={handleFileUpload}>
                        Bilder speichern
                    </button>
                </section>
            )}

            {showSuccess && (
                <section className="successform">
                    <h2>Dateien wurden gespeichert</h2>
                    <Link to="/jobS">Zu den Stellenanzeigen</Link>
                </section>
            )}
        </section>
    );
}

export default JobAddForm;
