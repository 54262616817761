import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FormListItems from "./FormListItems";
import apiurl from "../apiUrl";
import BackendMainContainer from "../layoutcomponents/BackendMainContainer";

function Forms({ isCollapsed }) {
    const fetchurl = apiurl + "getformdata.php";
    const [forms, setForms] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await fetch(fetchurl);
                const result = await response.json();
                setForms(result);
            } catch (error) {
                console.error('Fehler beim Abrufen von Formulardaten:', error);
            }
        };
        getData();
    }, []); // Keine Abhängigkeit angeben, damit der Effekt nur einmalig ausgeführt wird

    if (forms.length === 0) {
        return (
            <BackendMainContainer navIsCollapsed={isCollapsed}>
                <div className={"mioui-section-list"}>
                    <h1>Formulare</h1>
                    <p>Es wurden noch keine Formulare angelegt.</p>
                    <Link to="/backend/forms/add">Neues Formular anlegen</Link>
                </div>
            </BackendMainContainer>
        );
    } else {
        return (
            <BackendMainContainer navIsCollapsed={isCollapsed}>
                <div className={"mioui-section-list"}>
                    <h1>Formulare</h1>
                    <table>
                        <thead>
                        <tr>
                            <th>Name:</th>
                            <th>Bearbeiten</th>
                            <th>Löschen</th>
                        </tr>
                        </thead>
                        <tbody>
                        {forms.map((form) => (
                            <FormListItems key={form.id} form={form} />
                        ))}
                        </tbody>
                    </table>
                </div>
            </BackendMainContainer>
        );
    }
}

export default Forms;
