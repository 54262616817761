

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from '../evolve-framework/template/header.module.css';

export default function MainNavigation({ naviIsCollapsed, setNaviIsCollapsed, setLogged }) {
    const [activePage, setActivePage] = useState(1);
    const refNavigation = useRef();

    useEffect(() => {
        const toggleNavigation = () => {
            var media = window.matchMedia('(max-height:767px)');
            var mainNavigation = refNavigation.current;
            if (media.matches) {
                mainNavigation.classList.add(styles.scrollist);
            } else {
                mainNavigation.classList.remove(styles.scrollist);
            }
        };

        toggleNavigation();

        window.addEventListener("resize", toggleNavigation);

        return () => {
            window.removeEventListener("resize", toggleNavigation);
        };
    }, []);

    function manageLink(pageid) {
        setActivePage(pageid);
        let mobile = window.matchMedia('(max-width:1024px)');
        if (mobile.matches) {
            setNaviIsCollapsed(!naviIsCollapsed);
        }
    }

    const handleLogout = () => {
        setLogged(false);
        document.cookie = "loggedin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.href = "/";
    }

    return (
        <nav className={'main-navigation'} ref={refNavigation}>
            <ul>
                <li>
                    <Link
                        onClick={() => manageLink(1)}
                        className={activePage === 1 ? styles.activelink : ""}
                        to="/backend/customers">Kundendaten</Link>
                </li>
                <li>
                    <Link
                        onClick={() => manageLink(12)}
                        className={activePage === 12 ? styles.activelink : ""}
                        to="/backend/jobs">Stellenanzeigen</Link>
                    <ul>
                        <li>
                            <Link
                                onClick={() => manageLink(6)}
                                className={activePage === 6 ? styles.activelink : ""}
                                to="/backend/jobs/add">Neue Stellenanzeige anlegen</Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <Link
                        onClick={() => manageLink(6)}
                        className={activePage === 6 ? styles.activelink : ""}
                        to="/backend/forms">Formulare</Link>
                    <ul>
                        <li>
                            <Link
                                onClick={() => manageLink(7)}
                                className={activePage === 7 ? styles.activelink : ""}
                                to="/backend/forms/add">Neues Formular</Link>
                        </li>
                    </ul>
                </li>
                <li className={"pagewithoutlink"}>
                    <ul>
                        <li>
                            <Link
                                onClick={() => manageLink(4)}
                                className={activePage === 4 ? styles.activelink : ""}
                                to="/backend/pages/startseite">Startseite</Link>
                        </li>
                        <li>
                            <Link
                                onClick={() => manageLink(2)}
                                className={activePage === 2 ? styles.activelink : ""}
                                to="/backend/pages/uns">Über uns</Link>
                        </li>
                        <li>
                            <Link
                                onClick={() => manageLink(3)}
                                className={activePage === 3 ? styles.activelink : ""}
                                to="/backend/pages/impressum">Impressum</Link>
                        </li>
                        <li>
                            <Link
                                onClick={() => manageLink(4)}
                                className={activePage === 4 ? styles.activelink : ""}
                                to="/backend/pages/datenschutz">Datenschutz</Link>
                        </li>
                    </ul>
                </li>
                <li className={`${styles.statelist} ${styles.noborder}`}>
                    <Link
                        onClick={() => manageLink(8)}
                        className={activePage === 8 ? styles.activelink : ""}
                        to="/backend/tracking">Statistiken</Link>
                </li>
            </ul>
            <ul className={styles.lastitems}>
                <li className={`${styles.settings} ${styles.noborder}`}>
                    <Link
                        onClick={() => manageLink(15)}
                        className={activePage === 15 ? styles.activelink : ""}
                        to="/backend/settings">Einstellungen</Link>
                </li>
                <li
                    className={styles.listlogout}
                    onClick={handleLogout}
                >
                    Abmelden
                </li>
            </ul>
        </nav>
    )
}
