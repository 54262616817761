import React from "react";
import axios from "axios";

import frmstyles from "../styles/css/content/form.module.css";
import CtaButton from "../buttons/CtaButton";
import feapiurl from "../feapiurl";
import {Link} from "react-router-dom";
function UserForm({ selectedData, jobid,selectedAddon, formheadline, formcontent, sendtitle, sendcontent })
{
    const [formData, settFormData] = React.useState({livingcity:'',username:'',useremailadresse:'', usettelephone:'', qustionanswerids:[],addOnData:[], jobid:0,});

    const [isSend, setIsSend] = React.useState(false);
    const [dataState, setDataState] = React.useState('')
    const [showuserinfo, setUserInfo] = React.useState(false);
    const [shortNotice,setShortNotice] = React.useState('');
        const [showSubmitButton, setShowSubmitButton] = React.useState(false);


   const toggleButton = () =>{

       setShowSubmitButton(!showSubmitButton);
}
const handleshortinfodata = ()=>{

       setUserInfo(false);
}
    const handleForm = async (event) => {
        event.preventDefault();
        setIsSend(true);



        const postData = {
            username: formData.username,
            useremailadresse: formData.useremailadresse,
            usettelephone: formData.usettelephone,
            userlivingcity: formData.livingcity,
            qustionanswerids: selectedData,
            addOnData: selectedAddon,
            notice: shortNotice,
            jobid: jobid
        };

        try {
            const response = await axios.post(feapiurl + 'sendformdata.php', postData);

            if (response.status === 200) {

            } else {
            }
        } catch (error) {
        }
    }








    return(
                <section className={frmstyles.userformwrapper}>

                    {!isSend  && showuserinfo ? (



                                        <section className={frmstyles.userformcontent}>
                                            <h1>
                                                Kurzlebenslauf: Bitte beschreiben Sie kurz Ihren beruflichen Werdegang
                                            </h1>
                                            <form className={frmstyles.userform} onSubmit={handleshortinfodata}>
                                                <textarea
                                                    required="required"
                                                    onChange={(e)=>setShortNotice(e.target.value)}
                                                    className={frmstyles.textarea}
                                                />

                                                <button
                                                    className={"cta-button"}>Weiter</button>
                                            </form>
                                        </section>

                                           ) : null
                                }


                    {!isSend && !showuserinfo && (
                 <section className={frmstyles.userformcontent}>
                <h1
                    dangerouslySetInnerHTML={{ __html: formheadline }}
                />
               <form className={frmstyles.userform} onSubmit={handleForm}>






                   <label htmlFor="name">Vor- und Nachname</label>
                   <input
                       id="name"

                       type="text"
                       required="required"
                       value={formData.username}
                       onChange={(e)=>settFormData({...formData, username: e.target.value})}
                       />
            <label htmlFor="email">E-Mail Adresse</label>
                   <input
                      id="mail"
                       type="text"
                       required="required"
                       value={formData.useremailadresse}
                       onChange={(e)=>settFormData({...formData, useremailadresse: e.target.value})}
                       />
                   <label htmlFor="telephone">Telefonnummer</label>
                   <input
                      id="telephone"
                       type="text"
                       required="required"
                       value={formData.usettelephone}
                       onChange={(e)=>settFormData({...formData, usettelephone: e.target.value})}
                       />
                   <label htmlFor="email">Wohnort</label>
                   <input
                       id="mail"
                       type="text"
                       required="required"
                       value={formData.livingcity}
                       onChange={(e)=>settFormData({...formData, livingcity: e.target.value})}
                   />
                   <section className={formData.checkdse}>

                    <input
                    type="checkbox"
                    className={frmstyles.checkdse}
                    onClick={toggleButton}
                    />
                       <span className={frmstyles.dsetext}>Ich akzeptiere die <Link to="/datenschutz" target="_blank">Datenschutzerklärung</Link> </span>
                   </section>
                  <button
                 className={"cta-button"}
                disabled={ !showSubmitButton ? "disabled" :''}
                   style={
                       !showSubmitButton ?{opacity:0.5 } : {opacity:1}

                   }



>



                  Bewerbung absenden
                  </button>

               </form>
                 </section>
                        )}
                    {isSend  && (
                        <section className={frmstyles.formsubmitcontent}>

                        <h1>{sendtitle}</h1>
                            <p
                                dangerouslySetInnerHTML={{ __html: sendcontent }}
                            />



                        </section>
                        )}

                </section>

    )
}
export default UserForm;