import React from 'react';
import { createRoot } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const pathname = window.location.pathname;
const isFrontend = !pathname.startsWith('/backend');

const root = document.getElementById('root');

createRoot(root).render(
    <BrowserRouter>
        <App isFrontend={isFrontend} />
    </BrowserRouter>
);
