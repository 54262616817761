import logo from '../assets/evolve-logo.svg';
import { Link } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import headerstyles from "../evolve-framework/template/header.module.css";
import Menbutton from "../assets/menu.svg";
import React from "react";
function HeaderBackend({setIsLogged, setNaviIsCollapsed, navIsCollapsed}){


function changeNavPosition(){

    setNaviIsCollapsed(!navIsCollapsed);
}
React.useEffect(()=>{

        function checkMobile() {
            let mobile = window.matchMedia('(max-width:1024px)');
            if (mobile.matches) {

                setNaviIsCollapsed(true);
            }
        }
    window.addEventListener("load",checkMobile)

;
return () => {

    window.removeEventListener("load", checkMobile);
}
});




    return(

            <section className={

                navIsCollapsed ?

                headerstyles.evolveuinavigation + " "+ headerstyles.naviscollpapsed
                :
                    headerstyles.evolveuinavigation

            }>
            <section className={headerstyles.mobutton}>
            <img src={Menbutton}
            alt=""
            onClick={changeNavPosition}


            />


            </section>


                <section className={headerstyles.evolvelogo}>
                <Link to="/">
                    <img
                        alt={'evolve'}
                        src={logo}
                        title={"evolve"}
                    />
                </Link>
                </section>
<MainNavigation naviIsCollapsed={navIsCollapsed} setNaviIsCollapsed={setNaviIsCollapsed} setLogged = {setIsLogged}></MainNavigation>
            </section>

    )
}
export default HeaderBackend;