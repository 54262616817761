import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './styles/css/App.css';
import Header from './templates/Header';
import Footer from './templates/Footer';
import HomeContent from './content/HomeContent';
import Forms from './content/Forms';
import Impressum from './content/Impressum';
import Datenschutz from './content/Datenschutz';
import JobDetailView from './anzeigen/JobDetailView';
import Backend from './backend/Backend';

function FrontendRoutes() {
    return (
        <Routes>
            <Route path="/" element={<HomeContent />} />
            <Route path="/:joburl" element={<JobDetailView />} />
            <Route path="/jetzt-bewerben/:jobid" element={<Forms />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
        </Routes>
    );
}

function App() {
    const location = useLocation();
    const isFrontend = !location.pathname.startsWith('/backend');
    const [backendStylesLoaded, setBackendStylesLoaded] = useState(false);

    useEffect(() => {
        if (!isFrontend) {
            import('./backend/evolve-framework/evolve-ui-framework.scss')
                .then(() => setBackendStylesLoaded(true))
                .catch(error => console.error('Error loading backend styles:', error));
        }
    }, [isFrontend]);

    if (isFrontend) {
        return (
            <div className="App">
                <Header/>
                <FrontendRoutes/>
                <Footer/>
            </div>
        );
    } else {
        return (
            <div className="backend">
                {backendStylesLoaded && <Backend />}
            </div>
        );
    }
}

export default App;
